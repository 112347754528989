import { useState } from "react";

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
/**
 * Custom hook for handling form submissions.
 * @returns {Object} - The loading state, response, and submit function.
 */
const useSubmit = () => {
  const [isLoading, setLoading] = useState(false); // Manages loading state
  const [response, setResponse] = useState(null); // Stores the response data

  /**
   * Submits the form data to the given URL.
   * @param {string} url - The endpoint to submit the data to.
   * @param {Object} data - The form data to be submitted.
   */
  const submit = async (url, data) => {
    setLoading(true); // Set loading state to true while submitting

    try {
      await wait(1000);

      setResponse({
        type: "success",
        message: `Thank you for subscribing to our newsletter!`,
      });
    } catch (error) {
      setResponse({
        type: "error",
        message: "Something went wrong, please try again later!",
      });
    } finally {
      setLoading(false);
    }
  };

  return { isLoading, response, submit }; // Return the state and the submit function
};

export default useSubmit;
