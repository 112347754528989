import "./App.css";
import ContactMeSection from "./components/ContactMeSection";
import Footer from "./components/Footer";
import { ChakraProvider } from "@chakra-ui/react";
import { AlertProvider } from "./context/alertContext";
import Alert from "./components/Alert";
import LandingSection from "./components/LandingSection";
import Header from "./components/Header";
import theme from "./theme";
import About from "./components/About";
import ServicesSection from "./components/ServicesSection";
import Pricing from "./components/Pricing";
import "@fontsource/cinzel/400.css"; // Weight 400
import "@fontsource/cinzel/700.css"; // Weight 700
import "@fontsource/lora/400.css"; // Weight 400
import "@fontsource/lora/700.css"; // Weight 700
import WhatsAppButton from "./components/WhatsAppButton";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AlertProvider>
        <main>
          <Header />
          <LandingSection />
          <About />
          <ServicesSection />
          <Pricing />
          <ContactMeSection />
          <Footer />
          <WhatsAppButton />
          <Alert />
        </main>
      </AlertProvider>
    </ChakraProvider>
  );
}

export default App;
