// src/components/WhatsAppButton.js
import React from "react";
import { Box, Icon } from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const WhatsAppButton = () => {
  return (
    <MotionBox
      as="a"
      href="https://wa.me/+351929118950" // Replace with your WhatsApp number
      target="_blank"
      rel="noopener noreferrer"
      position="fixed"
      bottom={{ base: "20px", md: "40px" }}
      right={{ base: "20px", md: "40px" }}
      bg="yellow.400"
      color="white"
      borderRadius="full"
      p={{ base: 4, md: 9 }}
      boxSize="50px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      boxShadow="md"
      zIndex="1000"
      _hover={{ bg: "yellow.500" }}
      animate={{
        scale: [1, 1.2, 1],
        boxShadow: [
          "0 0 5px rgba(255, 255, 0, 0.4)",
          "0 0 15px rgba(255, 255, 0, 0.6)",
          "0 0 5px rgba(255, 255, 0, 0.4)",
        ],
      }}
      transition={{
        duration: 1.5,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "loop",
      }}
    >
      <Icon as={FaWhatsapp} boxSize={{ base: "24px", md: "40px" }} />
    </MotionBox>
  );
};

export default WhatsAppButton;
