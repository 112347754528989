import React from "react";
import { Box, Grid, Image, Heading, Text, Icon } from "@chakra-ui/react";
import { FaCut, FaSink } from "react-icons/fa";
import { GiBeard } from "react-icons/gi";
import { motion } from "framer-motion";
import CustomContainer from "./CustomContainer";
import { useTranslation } from "react-i18next";
import service_1 from "../assets/images/service_1.jpg";
import service_2 from "../assets/images/service_2.jpg";
import service_3 from "../assets/images/service_3.jpg";
const services = [
  {
    titleKey: "haircut",
    icon: FaCut,
    image: service_1,
    descriptionKey: "service_desc_haircut",
  },
  {
    titleKey: "beard",
    icon: GiBeard,
    image: service_2,
    descriptionKey: "service_desc_beard",
  },
  {
    titleKey: "hair_wash",
    icon: FaSink,
    image: service_3,
    descriptionKey: "service_desc_wash",
  },
];

const ServicesSection = () => {
  const { t } = useTranslation();
  return (
    <Box
      px={{ base: 5, md: 10 }}
      py={{ base: 5, md: 32 }}
      bg="yellow.50"
      id="services"
    >
      <CustomContainer>
        <Heading as="h2" size="xl" textAlign="center" mb={10}>
          {t("our_services")}
        </Heading>
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "repeat(3, 1fr)" }}
          gap={6}
        >
          {services.map((service, index) => (
            <ServiceCard key={index} service={service} />
          ))}
        </Grid>
      </CustomContainer>
    </Box>
  );
};

const ServiceCard = ({ service }) => {
  const { t } = useTranslation();
  return (
    <Box
      as={motion.div}
      whileHover={{ scale: 1.05 }}
      transition="0.3s"
      maxWidth="350px"
      maxHeight="500px"
      borderRadius="md"
      overflow="hidden"
      boxShadow="md"
      position="relative"
      mx="auto"
      bg="white"
    >
      <Image
        src={service.image}
        alt={service.title}
        width="100%"
        height="100%"
        objectFit="cover"
      />
      <Box
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        bg="rgba(0, 0, 0, 0.6)"
        color="white"
        p={4}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Icon as={service.icon} boxSize={6} />
        <Text fontSize="lg" fontWeight="bold">
          {t(service.titleKey)}
        </Text>
      </Box>
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(0, 0, 0, 0.8)"
        color="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        opacity="0"
        transition="opacity 0.3s"
        _hover={{ opacity: 1 }}
        p={4}
      >
        <Heading as="h3" size="lg" mb={2}>
          {t(service.titleKey)}
        </Heading>
        <Text textAlign="center">{t(service.descriptionKey)}</Text>
      </Box>
    </Box>
  );
};

export default ServicesSection;
