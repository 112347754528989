import React from "react";
import {
  Box,
  Grid,
  Heading,
  Text,
  VStack,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import CustomContainer from "./CustomContainer";
import { useTranslation } from "react-i18next";
import about from "../assets/images/about.jpg";
const About = () => {
  const { t } = useTranslation();
  const imageSize = useBreakpointValue({ base: "100%", md: "50%" });

  return (
    <Box
      as="section"
      py={{ base: 10, md: 32 }}
      px={{ base: 5, md: 10 }}
      bg="yellow.50"
      id="about"
    >
      <CustomContainer>
        <Grid
          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
          gap={10}
          alignItems="center"
        >
          {/* Images Column */}
          <VStack order={{ base: 2, md: 1 }}>
            <Image
              src={about}
              alt="About Us"
              borderRadius="md"
              boxShadow="lg"
              width={imageSize}
              // maxW="300px"
            />
          </VStack>

          {/* Content Column */}
          <VStack align="start" spacing={5} order={{ base: 1, md: 2 }}>
            <Heading as="h2" size="xl" color="gray.800">
              {t("about_us")}
            </Heading>
            <Text fontSize="md" color="gray.600">
              {t("about_content1")}
            </Text>
            <Text fontSize="md" color="gray.600">
              {t("about_content2")}
            </Text>
            <Text fontSize="md" color="gray.600">
              {t("about_content3")}
            </Text>
          </VStack>
        </Grid>
      </CustomContainer>
    </Box>
  );
};

export default About;
