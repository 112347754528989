import React from "react";
import {
  Box,
  Grid,
  Heading,
  Text,
  HStack,
  VStack,
  Image,
} from "@chakra-ui/react";
import CustomContainer from "./CustomContainer";
import logo300 from "../assets/images/logo-300.png";
import { useTranslation } from "react-i18next";

const services = [
  {
    titleKey: "kids_cut",
    price: "8 euro",
    descriptionKey: "desc_kids_cut",
  },
  {
    titleKey: "haircut",
    price: "8 euro",
    descriptionKey: "desc_haircut",
  },
  {
    titleKey: "specialty_haircut",
    price: "10 euro",
    descriptionKey: "desc_specialty_haircut",
  },
  {
    titleKey: "beard",
    price: "5 euro",
    descriptionKey: "desc_beard",
  },
  {
    titleKey: "haircut_beard",
    price: "13 euro",
    descriptionKey: "desc_haircut_beard",
  },
  {
    titleKey: "skin",
    price: "8 euro",
    descriptionKey: "desc_skin",
  },
  {
    titleKey: "vip",
    price: "20 euro",
    descriptionKey: "desc_vip",
  },
];

const PricingSection = () => {
  const { t } = useTranslation();
  return (
    <Box
      py={{ base: 10, md: 32 }}
      px={{ base: 5, md: 10 }}
      bg="gray.900"
      color="white"
      id="pricing"
    >
      <CustomContainer>
        <Image
          src={logo300}
          alt="Logo"
          height={{ base: "80px", md: "150px", lg: "300px" }}
          my={5}
          mx="auto"
        />
        <Heading
          as="h2"
          size="xl"
          textAlign="center"
          mb={10}
          color="white"
          fontSize={{ base: "2xl", md: "4xl" }}
        >
          {t("our_pricing")}
        </Heading>
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={10}>
          {services.map((service, index) => (
            <Box key={index} borderRadius="md" boxShadow="md" p={4}>
              <VStack align="start" spacing={2}>
                <HStack justify="space-between" width="100%">
                  <Text fontWeight="bold" fontSize={{ base: "sm", md: "md" }}>
                    {t(service.titleKey)}
                  </Text>
                  <Box
                    flex="1"
                    h="1px"
                    borderBottom="1px dotted white"
                    mx={2}
                  />
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: "sm", md: "md" }}
                    color="yellow.600"
                  >
                    {service.price}
                  </Text>
                </HStack>
                <Text fontSize={{ base: "xs", md: "md" }}>
                  {t(service.descriptionKey)}
                </Text>
              </VStack>
            </Box>
          ))}
        </Grid>
      </CustomContainer>
    </Box>
  );
};

export default PricingSection;
