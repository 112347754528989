import {
  VStack,
  HStack,
  Heading,
  Input,
  FormLabel,
  FormControl,
  Box,
  Textarea,
  Button,
  useBreakpointValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomContainer from "./CustomContainer";
import { useTranslation } from "react-i18next";

const ContactMeSection = () => {
  const { t } = useTranslation();
  const iframeHeight = useBreakpointValue({ base: "300px", md: "100%" });
  const phoneRegExp = /^[0-9]+$/;

  const formik = useFormik({
    initialValues: {
      name: "",
      tel: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      tel: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Required"),
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2))
      // submit("https://formsubmit.co/bd82f68a8f684e18393efc2911096761", values);
    },
  });

  return (
    <Box
      py={{ base: 10, md: 32 }}
      px={{ base: 5, md: 10 }}
      id="contact"
      bg="yellow.50"
    >
      <CustomContainer>
        <HStack
          spacing={10}
          align="stretch"
          wrap={{ base: "wrap", md: "nowrap" }}
          height="100%"
        >
          <VStack
            as="form"
            spacing={4}
            align="start"
            width={{ base: "100%", md: "40%" }}
            pb={{ base: "0", md: "20" }}
            action="https://formsubmit.co/68ed9818b685114b6bb69ac676f2f48c"
            method="POST"
          >
            <input type="text" name="_honey" style={{ display: "none" }} />

            <input type="hidden" name="_captcha" value="true" />

            <Heading as="h1" size="xl" mb={4} letterSpacing="wide">
              {t("contact_us")}
            </Heading>
            <FormControl
              isRequired
              isInvalid={formik.touched.name && formik.errors.name}
            >
              <FormLabel htmlFor="name">{t("name")}</FormLabel>
              <Input
                id="name"
                name="name"
                placeholder="Your Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="name"
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={formik.touched.tel && formik.errors.tel}
            >
              <FormLabel htmlFor="tel">{t("phone")}</FormLabel>
              <Input
                id="tel"
                name="tel"
                type="tel"
                placeholder="Your Phone number"
                value={formik.values.tel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="tel"
              />
              <FormErrorMessage>{formik.errors.tel}</FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={formik.touched.message && formik.errors.message}
            >
              <FormLabel htmlFor="message">{t("message")}</FormLabel>
              <Textarea
                id="message"
                name="message"
                height={100}
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete="off"
              />
              <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
            </FormControl>

            <Button type="submit" colorScheme="yellow" width="full">
              {t("submit")}
            </Button>
          </VStack>
          <VStack width={{ base: "100%", md: "60%" }}>
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3003.875358858699!2d-8.611113924433916!3d41.15907101012699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24659b6b23892f%3A0x7a7cdc841b442ab6!2sRoyal%20barbershop!5e0!3m2!1sen!2spt!4v1716415584030!5m2!1sen!2spt"
              width="100%"
              height={iframeHeight}
              // style={{ border: "0" }}
              style={{
                border: "2px solid black",
                // borderRadius: "25px",
                filter: "sepia(30%)",
              }}
              loading="lazy"
            ></iframe>
          </VStack>
        </HStack>
      </CustomContainer>
    </Box>
  );
};

export default ContactMeSection;
