import React, { useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  Text,
  useDisclosure,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";

const MotionBox = motion(Box);

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    onClose();
  };

  const displayText = useBreakpointValue({ base: false, md: true });

  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        onClose();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen, onClose]);

  return (
    <Box position="relative" display="inline-block">
      <Button
        onClick={isOpen ? onClose : onOpen}
        bg="transparent"
        color="white"
        _hover={{ bg: "gray.700" }}
        _active={{ bg: "gray.600" }}
        rightIcon={
          <MotionBox
            as={ChevronDownIcon}
            boxSize={{ base: 5, md: 8 }}
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          />
        }
        iconSpacing={{ base: 0, md: 4 }}
      >
        <Box as="span" mr={{ base: 0, md: 2 }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            width="24"
            fill="#fff"
          >
            <path d="m11.9 22 4.55-12h2.1l4.55 12H21l-1.05-3.05H15.1L14 22Zm3.8-4.8h3.6l-1.75-4.95h-.1ZM4 19l-1.4-1.4 5.05-5.05q-.95-1.05-1.662-2.175Q5.275 9.25 4.75 8h2.1q.45.9.963 1.625.512.725 1.237 1.525 1.1-1.2 1.825-2.462Q11.6 7.425 12.1 6H1V4h7V2h2v2h7v2h-2.9q-.525 1.775-1.425 3.45-.9 1.675-2.225 3.15l2.4 2.45-.75 2.05L9 14Z"></path>
          </svg>
        </Box>
        {displayText && i18n.language.toUpperCase()}
      </Button>

      <AnimatePresence>
        {isOpen && (
          <MotionBox
            position="absolute"
            top="40px"
            right="0"
            width="100%"
            bg="gray.900"
            borderRadius="md"
            boxShadow="md"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            zIndex="1"
          >
            <VStack align="stretch" p={2}>
              <Text
                px={4}
                py={2}
                _hover={{ bg: "gray.700" }}
                borderRadius="md"
                cursor="pointer"
                onClick={() => handleLanguageChange("en")}
              >
                EN
              </Text>
              <Text
                px={4}
                py={2}
                _hover={{ bg: "gray.700" }}
                borderRadius="md"
                cursor="pointer"
                onClick={() => handleLanguageChange("pt")}
              >
                PT
              </Text>
            </VStack>
          </MotionBox>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default LanguageSwitcher;
