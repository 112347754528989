import React, { useState } from "react";
import { Box, Spinner, Button, Heading, Text, VStack } from "@chakra-ui/react";
import { FaCrown } from "react-icons/fa";
import { motion } from "framer-motion";
import CustomContainer from "./CustomContainer";
import { useTranslation } from "react-i18next";
import heroVideoWebm from "../assets/videos/hero.webm";
import heroVideoMp4 from "../assets/videos/hero.mp4";

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionButton = motion(Button);

const containerVariant = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const itemVariant = {
  hidden: { opacity: 0, y: 100 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.3, ease: "easeOut" } },
};

const LandingSection = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const handleVideoLoad = () => {
    setLoading(false);
  };

  return (
    <Box
      position="relative"
      width="100%"
      height="100vh"
      overflow="hidden"
      id="home"
    >
      {loading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="rgba(0, 0, 0, 0.8)"
          zIndex="2"
        >
          <Spinner size="xl" color="yellow.400" />
        </Box>
      )}

      <Box
        as="video"
        autoPlay
        loop
        muted
        playsInline
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        objectFit="cover"
        zIndex="-1"
        onLoadedData={handleVideoLoad} // Event listener to hide spinner when video loads
        preload="auto"
      >
        <source src={heroVideoWebm} type="video/webm" />
        <source src={heroVideoMp4} type="video/mp4" />
      </Box>

      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        bg="rgba(0, 0, 0, 0.6)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          bg="rgba(0, 0, 0, 0)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="1"
        >
          <Box width="100%" px={{ base: 5, sm: 5, md: 10, lg: 10 }} bg="dark">
            <CustomContainer>
              <MotionBox
                as={VStack}
                spacing={8}
                alignItems="center"
                initial="hidden"
                animate="visible"
                variants={containerVariant}
              >
                <MotionText
                  fontSize={{ base: "sm", sm: "md", md: "lg" }}
                  fontWeight="medium"
                  color="yellow.400"
                  textTransform="uppercase"
                  letterSpacing="wider"
                  variants={itemVariant}
                >
                  {t("your_premier_barber")}
                </MotionText>

                <MotionHeading
                  as="h1"
                  size={{ base: "2xl", sm: "3xl", md: "4xl" }}
                  color="white"
                  textAlign="center"
                  variants={itemVariant}
                >
                  {t("welcome_message")}
                </MotionHeading>

                <MotionText
                  fontSize={{ base: "md", sm: "lg", md: "xl" }}
                  fontWeight="normal"
                  color="gray.300"
                  textAlign="center"
                  maxW="600px"
                  variants={itemVariant}
                >
                  {t("subtitle_message")}
                </MotionText>

                <MotionButton
                  colorScheme="yellow"
                  size={{ base: "sm", sm: "md", md: "lg" }}
                  variant="outline"
                  px={10}
                  py={8}
                  leftIcon={<FaCrown />}
                  variants={itemVariant}
                >
                  {t("make_appointment")}
                </MotionButton>
              </MotionBox>
            </CustomContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingSection;
