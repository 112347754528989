// src/components/Header.js
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  useDisclosure,
  VStack,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import logo60 from "../assets/images/logo-60.png";
import logo80 from "../assets/images/logo-80.png";
import CustomContainer from "./CustomContainer";
import LanguageSwitcher from "./LanguageSwitcher"; // Import the LanguageSwitcher component
import { useTranslation } from "react-i18next";

const Links = ["home", "about", "services", "pricing", "contact"];

const NavLink = ({ children, onClose, ...props }) => {
  const { t } = useTranslation();
  return (
    <Link
      px={2}
      py={1}
      fontWeight="medium"
      letterSpacing="wider"
      _hover={{
        color: "yellow.500",
        textDecoration: "underline",
      }}
      href={`#${children}`}
      onClick={onClose}
      {...props}
    >
      {t(children)}
    </Link>
  );
};

export default function Header() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Tracks whether the navbar should have a background and box shadow based on the scroll position.
  const [scrollNav, setScrollNav] = useState(false);

  // Tracks whether the navbar should be visible or hidden based on the scroll direction.
  const [showNav, setShowNav] = useState(true);

  // Keeps track of the last scroll position to determine the scroll direction.
  const [lastScrollY, setLastScrollY] = useState(0);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  // Memoize controlNavbar using useCallback, ensuring
  // it doesn't change between renders unless its dependencies change.
  // thus preventing unnecessary re-renders and resolving the warning.
  const controlNavbar = useCallback(() => {
    if (typeof window !== "undefined") {
      if (window.scrollY > lastScrollY) {
        // If scrolling down, hide the navbar
        setShowNav(false);
      } else {
        // If scrolling up, show the navbar
        setShowNav(true);
      }
      setLastScrollY(window.scrollY);
    }
  }, [lastScrollY]);

  // The useEffect hook ensures that event listeners
  // are added when the component mounts and removed
  // when the component unmounts to prevent memory leaks.
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", changeNav);
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY, controlNavbar]);

  const bgColor = isOpen
    ? "gray.900"
    : scrollNav
    ? { base: "gray.900", md: "gray.900" }
    : { base: "transparent", md: "transparent" };

  return (
    <Box
      position="fixed"
      w="100%"
      transform={showNav ? "translateY(0)" : "translateY(-100%)"}
      transition="transform 0.3s ease-in-out"
      color="white"
      px={{ base: 5, md: 10 }}
      boxShadow={scrollNav ? "lg" : "none"}
      zIndex="10"
      bg={bgColor}
    >
      <CustomContainer>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Box>
            <picture>
              <source media="(min-width: 48em)" srcSet={logo80} />
              <Image
                src={logo60}
                alt="Logo"
                height={{ base: "60px", md: "80px" }}
                my={{ base: 3, md: 5 }}
                bg="white"
                borderRadius="50%"
              />
            </picture>
          </Box>
          <HStack spacing={{ md: 4, lg: 8 }} alignItems={"center"}>
            <LanguageSwitcher /> {/* Use the LanguageSwitcher component */}
            <HStack display={{ base: "none", md: "flex" }}>
              {Links.map((link) => (
                <NavLink key={link} onClose={onClose}>
                  {link}
                </NavLink>
              ))}
            </HStack>
            <IconButton
              size={"md"}
              icon={
                isOpen ? (
                  <CloseIcon />
                ) : (
                  <HamburgerIcon w={7} h={7} /> // Adjust width and height here
                )
              }
              aria-label={"Open Menu"}
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
              w="50px"
              h="50px"
              borderRadius="50%"
              bg="transparent"
              color="white"
              _hover={{
                bg: "yellow.50",
                color: "black",
              }}
              _active={{
                bg: "yellow.50",
              }}
            />
          </HStack>
        </Flex>
      </CustomContainer>

      {isOpen ? (
        <Box bg="gray.900" display={{ md: "none" }}>
          <VStack as={"nav"} spacing={0}>
            {Links.map((link) => (
              <NavLink key={link} p={5} onClose={onClose}>
                {link}
              </NavLink>
            ))}
          </VStack>
        </Box>
      ) : null}
    </Box>
  );
}
