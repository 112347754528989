// src/theme.js
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: "'Cinzel', serif",
    body: "'Lora', serif",
  },
  sizes: {
    containerMaxWidth: "1200px",
  },
});

export default theme;
