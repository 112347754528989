// src/components/CustomContainer.js
import React from "react";
import { Box } from "@chakra-ui/react";
import { useTheme } from "@chakra-ui/react";

const CustomContainer = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Box maxW={theme.sizes.containerMaxWidth} mx="auto" {...props}>
      {children}
    </Box>
  );
};

export default CustomContainer;
