import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Heading,
  Text,
  Link,
  VStack,
  HStack,
  Input,
  Button,
  Icon,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";

import { CiLocationOn, CiMobile4, CiAt } from "react-icons/ci";
import { useFormik } from "formik";
import * as Yup from "yup";
import useSubmit from "../hooks/useSubmit";
import { useAlertContext } from "../context/alertContext";
import CustomContainer from "./CustomContainer";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { onOpen } = useAlertContext();
  const { isLoading, response, submit } = useSubmit();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid Email").required("Required"),
    }),
    onSubmit: (values) => {
      //   alert(JSON.stringify(values, null, 2));
      submit("", values);
    },
  });

  //You are using formik and onOpen inside the useEffect hook, but they are not listed
  //in the dependency array.
  //If formik or onOpen change (which they might if they depend on props or other state),
  //your effect could use stale values, leading to unpredictable behavior.

  // useEffect(() => {
  //   if (response) {
  //     onOpen(response.type, response.message);
  //     if (response.type === "success") {
  //       formik.resetForm();
  //     }
  //   }
  // }, [response]);

  const { resetForm } = formik;
  useEffect(() => {
    if (response) {
      onOpen(response.type, response.message);
      if (response.type === "success") {
        resetForm();
      }
    }
  }, [response, resetForm, onOpen]);
  // Include necessary dependencies
  return (
    <Box as="footer" bg="gray.800" color="white" px={{ base: 5, md: 10 }}>
      <CustomContainer>
        <Grid
          py={{ base: 10, md: 32 }}
          templateColumns={{
            base: "1fr",
            md: "repeat(2,1fr)",
            lg: "repeat(3, 1fr)",
          }}
          gap={10}
        >
          {/* Contact Information */}
          <Box>
            <Heading as="h3" size="md" mb={4}>
              {t("contact_info")}
            </Heading>
            <VStack align="start" fontSize={{ base: "14px", sm: "16px" }}>
              <HStack>
                <Icon as={CiLocationOn} boxSize={5} color="yellow" />

                <Link
                  href="https://maps.app.goo.gl/8NNADwpx5VAFWoAQ9"
                  isExternal
                >
                  R. de Faria Guimarães 193, 4000-206 Porto
                </Link>
                {/* Adress */}
              </HStack>
              <Text></Text>
              <HStack>
                <Icon as={CiMobile4} boxSize={5} color="yellow" />

                <Link href="tel:+351929118950">+351 929 118 950</Link>
                {/* Phone number */}
              </HStack>
              <HStack>
                <Icon as={BsTelephone} boxSize={5} color="yellow" />

                <Link href="tel:+351221115133">+351 221 115 133</Link>
                {/* Phone number */}
              </HStack>
              <HStack>
                <Icon as={CiAt} boxSize={5} color="yellow" />
                <Link href="mailto:RoyalBarber.Porto@gmail.com">
                  RoyalBarber.Porto@gmail.com{" "}
                </Link>
                {/* Email */}
              </HStack>

              <HStack spacing={4} mt={4}>
                <Link href="#" isExternal>
                  <Icon as={FaFacebook} boxSize={6} />
                </Link>

                <Link
                  href="https://www.instagram.com/royalbarber.porto"
                  isExternal
                >
                  <Icon as={FaInstagram} boxSize={6} />
                </Link>
              </HStack>
            </VStack>
          </Box>

          {/* Worktime */}
          <Box maxWidth="400px" w="100%">
            <Heading as="h3" size="md" mb={4}>
              {t("worktime")}
            </Heading>
            <VStack align="start" fontSize={{ base: "14px", sm: "16px" }}>
              <HStack width="100%">
                <Text w={{ base: "80px", sm: "100px" }}>{t("monday")}:</Text>
                <Box flex="1" h="1px" bg="white" mx={2} />
                <Text whiteSpace="nowrap">9:00 AM - 9:00 PM</Text>
              </HStack>
              <HStack width="100%">
                <Text w={{ base: "80px", sm: "100px" }}>{t("tuesday")}:</Text>
                <Box flex="1" h="1px" bg="white" mx={2} />
                <Text whiteSpace="nowrap">9:00 AM - 9:00 PM</Text>
              </HStack>
              <HStack width="100%">
                <Text w={{ base: "80px", sm: "100px" }}>{t("wednesday")}:</Text>
                <Box flex="1" h="1px" bg="white" mx={2} />
                <Text whiteSpace="nowrap">9:00 AM - 9:00 PM</Text>
              </HStack>
              <HStack width="100%">
                <Text w={{ base: "80px", sm: "100px" }}>{t("thursday")}:</Text>
                <Box flex="1" h="1px" bg="white" mx={2} />
                <Text whiteSpace="nowrap">9:00 AM - 9:00 PM</Text>
              </HStack>
              <HStack width="100%">
                <Text w={{ base: "80px", sm: "100px" }}>{t("friday")}:</Text>
                <Box flex="1" h="1px" bg="white" mx={2} />
                <Text whiteSpace="nowrap">9:00 AM - 9:00 PM</Text>
              </HStack>
              <HStack width="100%">
                <Text w={{ base: "80px", sm: "100px" }}>{t("saturday")}:</Text>
                <Box flex="1" h="1px" bg="white" mx={2} />
                <Text whiteSpace="nowrap">9:00 AM - 9:00 PM</Text>
              </HStack>

              <HStack width="100%">
                <Text w={{ base: "80px", sm: "100px" }}>{t("sunday")}:</Text>
                <Box flex="1" h="1px" bg="white" mx={2} />
                <Text whiteSpace="nowrap">9:00 AM - 9:00 PM</Text>
              </HStack>
            </VStack>
          </Box>

          {/* Subscribe */}
          <Box
            gridColumn={{ base: "span 1", md: "span 2", lg: "auto" }}
            w="100%"
            maxWidth={{ base: "400px", md: "600px" }}
            mx={{ base: "0", md: "auto" }}
          >
            <Heading as="h3" size="md" mb={4}>
              {t("subscribe")}
            </Heading>
            <VStack align="start" fontSize={{ base: "14px", sm: "16px" }}>
              <Text>{t("subscribe_sub")}</Text>
              <HStack
                as="form"
                w="full"
                spacing={1}
                onSubmit={formik.handleSubmit}
                alignItems="start"
              >
                <FormControl
                  isInvalid={formik.touched.email && formik.errors.email}
                >
                  <Input
                    name="email"
                    placeholder={t("subscribe_placeholder")}
                    bg="white"
                    color="black"
                    type="email"
                    size="sm"
                    {...formik.getFieldProps("email")}
                    required
                  />
                  <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>

                <Button
                  type="submit"
                  colorScheme="yellow"
                  variant="outline"
                  size="sm"
                  isLoading={isLoading}
                >
                  {t("subscribe")}
                </Button>
              </HStack>
            </VStack>
          </Box>
        </Grid>
        <Text
          textAlign="start"
          mt={10}
          py={10}
          fontSize="xs"
          letterSpacing="widest"
        >
          &copy; {new Date().getFullYear()} RoyalBarber. All rights reserved.
          <br />
          <Text as="span" fontWeight="bold" color="blue.500">
            Made by YTDev.
          </Text>
        </Text>
      </CustomContainer>
    </Box>
  );
};

export default Footer;
